import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const DocumentRow = ({ doc }) => {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    doc.getDownloadURL()
      .then((url) => setUrl(url))
      .finally(() => setLoading(false))
  }, []) // eslint-disable-line

  return (
    <Grid key={doc.fullPath} item xs={12}>
      <Grid container alignItems='center'>
        <Grid item xs={10} alignItems='center'>
          <Typography>
            {doc.name}
          </Typography>
        </Grid>
        <Grid item xs={2}>{
          loading
            ? <CircularProgress />
            : <IconButton component='a' target='__blank' href={url}><OpenInNewIcon /></IconButton>
        }
        </Grid>
      </Grid>
    </Grid>
  )
}

const DocumentListField = ({ storageRef }) => {
  const [docs, setDocs] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    storageRef.listAll()
      .then((res) => {
        console.log()
        setDocs(res.items)
      })
      .finally(() => setLoading(false))
  }, []) // eslint-disable-line

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={1}>
      {docs.map(doc => {
        console.log('->    DocumentListField   doc', doc)
        return (
          <DocumentRow key={doc.fullPath} doc={doc} />
        )
      })}

    </Grid>
  )
}

export default DocumentListField
