// JobOffer.js
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  Create,
  ArrayField,
  SingleFieldList,
  DateInput,
  BooleanInput,
  DateField,
  BooleanField,
  EmailField,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'

export const JobOfferList = (props) => (
  <List {...props}>
    <Datagrid rowClick='show'>
      <TextField source='title' />
      <TextField source='company' />
      <DateField source='datePosted' />
      <BooleanField source='isActive' />
      <EmailField source='contact.email' />
    </Datagrid>
  </List>
)

export const JobOfferShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='title' />
      <TextField source='company' />
      <DateField source='datePosted' />
      <BooleanField source='isActive' />
      <TextField source='description' />
      <ArrayField source='requirements'>
        <SingleFieldList>
          <TextField source='value' />
        </SingleFieldList>
      </ArrayField>
      <TextField source='budget' />
      <TextField source='duration' />
      <TextField source='contact.name' />
      <EmailField source='contact.email' />
      <TextField source='contact.phone' />
    </SimpleShowLayout>
  </Show>
)

export const JobOfferEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source='title' />
      <TextInput source='company' />
      <DateInput source='datePosted' />
      <BooleanInput source='isActive' />
      <TextInput multiline source='description' />
      <ArrayInput source='requirements'>
        <SimpleFormIterator>
          <TextInput source='value' />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source='budget' />
      <TextInput source='duration' />
      <TextInput source='contact.name' />
      <TextInput source='contact.email' />
      <TextInput source='contact.phone' />
    </SimpleForm>
  </Edit>
)

export const JobOfferCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='title' />
      <TextInput source='company' />
      <DateInput source='datePosted' />
      <BooleanInput source='isActive' />
      <TextInput multiline source='description' />
      <ArrayInput source='requirements'>
        <SimpleFormIterator>
          <TextInput source='value' />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source='budget' />
      <TextInput source='duration' />
      <TextInput source='contact.name' />
      <TextInput source='contact.email' />
      <TextInput source='contact.phone' />
    </SimpleForm>
  </Create>
)
