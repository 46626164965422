import { TextInput } from 'react-admin'
import { Flex } from '@leanlancer/ui'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 4,
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}))

const AddressInput = ({ ...props }) => {
  const classes = useStyles()
  return (
    <Flex dir='ttb' className={classes.container}>
      <TextInput {...props} label='Line 1' source='address.address_line_1' />
      <TextInput {...props} label='Line 2' source='address.address_line_2' />
      <TextInput {...props} label='Line 3' source='address.address_line_3' />
      <TextInput {...props} label='Line 4' source='address.address_line_4' />
      <TextInput {...props} label='City' source='address.address_city' />
      <TextInput {...props} label='State' source='address.address_state' />
      <TextInput {...props} label='Country' source='address.address_country' />
      <TextInput {...props} label='Postcode' source='address.address_postcode' />
      <TextInput {...props} label='Additional Info' source='address.address_additional_info' />
    </Flex>
  )
}

AddressInput.defaultProps = {
  addLabel: true,
  label: 'Address'
}

export default AddressInput
