import React from 'react'
import {
  ArrayField,
  ChipField,
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField
} from 'react-admin'
import CVFileListField from '../components/fields/CVFileListField'
import ProfilePictureField from '../components/fields/ProfilePictureField'
import DefaultPagination from '../components/layout/DefaultPagination'

export const FreelancerProfilesList = ({ ...props }) => (
  <List
    pagination={<DefaultPagination />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='generalInfo.firstName' label='First Name' />
      <TextField source='generalInfo.lastName' label='Last Name' />
      <ArrayField source='skills'><SingleFieldList><ChipField source='id' /></SingleFieldList></ArrayField>
      <ArrayField source='languages'><SingleFieldList><ChipField source='language' /></SingleFieldList></ArrayField>
    </Datagrid>
  </List>
)

export const FreelancerProfilesShow = ({ ...props }) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ProfilePictureField />
        <TextField source='id' />
        <TextField source='generalInfo.firstName' label='First Name' />
        <TextField source='generalInfo.lastName' label='Last Name' />
        <TextField source='generalInfo.city' label='City' />
        <TextField source='generalInfo.country' label='Country' />
        <TextField source='generalInfo.phone' label='Phone Number' />
        <TextField source='biography' />
        <ArrayField source='languages'>
          <Datagrid>
            <TextField source='language' />
            <TextField source='proficiency' />
          </Datagrid>
        </ArrayField>
        <ArrayField source='skills'><SingleFieldList><ChipField source='id' /></SingleFieldList></ArrayField>
        <CVFileListField />
      </SimpleShowLayout>
    </Show>
  )
}
