// in src/App.js
import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from 'react-admin-firebase'
import { createBrowserHistory } from 'history'

import CustomLoginPage from './components/pages/CustomLoginPage'
import CustomLayout from './components/layout/CustomLayout'

import { FreelancerProfilesList, FreelancerProfilesShow } from './resources/FreelancerProfiles'
import { ProjectsCreate, ProjectsEdit, ProjectsList, ProjectsShow } from './resources/Projects'
import { UserList, UserShow } from './resources/Users'
import { ProjectMilestonesCreate, ProjectMilestonesEdit, ProjectMilestonesList, ProjectMilestonesShow } from './resources/ProjectMilestones'
import { ProjectTasksCreate, ProjectTasksEdit, ProjectTasksList, ProjectTasksShow } from './resources/ProjectTasks'
import { ClientProfilesList, ClientProfilesShow } from './resources/ClientProfiles'
import { ContactCreate, ContactEdit, ContactList, ContactShow } from './resources/Contacts'

import WorkIcon from '@material-ui/icons/Work'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PeopleIcon from '@material-ui/icons/People'
import ContactsIcon from '@material-ui/icons/Contacts'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'
import AssignmentIcon from '@material-ui/icons/Assignment'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { LeadsList, LeadsShow } from './resources/Leads'
import adminTheme from './adminTheme'
import { ClientInvoiceCreate, ClientInvoiceEdit, ClientInvoicesList } from './resources/ClientInvoices'
import { JobOfferCreate, JobOfferEdit, JobOfferList, JobOfferShow } from './resources/JobOffers'

const options = {
  app: firebase,
  logging: false,
  persistence: 'local',
  disableMeta: true
}
const dataProvider = FirebaseDataProvider({}, options)
const authProvider = FirebaseAuthProvider({}, options)
const history = createBrowserHistory()

const App = () => {
  return (
    <Admin
      history={history}
      theme={adminTheme}
      layout={CustomLayout}
      loginPage={CustomLoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        name='users'
        icon={PeopleIcon}
        list={UserList}
        show={UserShow}
      />
      <Resource
        name='contacts'
        icon={ContactsIcon}
        list={ContactList}
        show={ContactShow}
        edit={ContactEdit}
        create={ContactCreate}
      />
      <Resource
        name='leads'
        icon={TrendingUpIcon}
        list={LeadsList}
        show={LeadsShow}
      />
      <Resource
        name='freelancer_profiles'
        icon={AccountCircleIcon}
        list={FreelancerProfilesList}
        show={FreelancerProfilesShow}
      />
      <Resource
        name='client_profiles'
        icon={AccountCircleIcon}
        list={ClientProfilesList}
        show={ClientProfilesShow}
      />
      <Resource
        name='projects'
        icon={WorkIcon}
        create={ProjectsCreate}
        list={ProjectsList}
        show={ProjectsShow}
        edit={ProjectsEdit}
      />
      <Resource
        name='project_milestones'
        icon={FilterHdrIcon}
        create={ProjectMilestonesCreate}
        list={ProjectMilestonesList}
        show={ProjectMilestonesShow}
        edit={ProjectMilestonesEdit}
      />
      <Resource
        name='project_tasks'
        icon={AssignmentIcon}
        create={ProjectTasksCreate}
        list={ProjectTasksList}
        show={ProjectTasksShow}
        edit={ProjectTasksEdit}
      />
      <Resource
        name='client_invoices'
        icon={ReceiptIcon}
        create={ClientInvoiceCreate}
        list={ClientInvoicesList}
        show={ClientProfilesShow}
        edit={ClientInvoiceEdit}
      />
      <Resource
        name='job_offers'
        list={JobOfferList}
        show={JobOfferShow}
        edit={JobOfferEdit}
        create={JobOfferCreate}
      />
    </Admin>
  )
}

export default App
