// LoginPage.js
import React from 'react'
import { Login, LoginForm } from 'react-admin'
import ForgotPasswordButton from './CustomForgotPassword'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GoogleSignInButton, FacebookSignInButton } from '@leanlancer/ui'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 720
  },
  forgotPasswordButton: {
    margin: theme.spacing(1)
  },
  oauthContainer: {
    padding: theme.spacing(1)
  }
}))

const CustomLoginForm = props => {
  const classes = useStyles()
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={6}>
        <Typography variant='h6' align='center'>Sign In with Email/Password</Typography>
        <LoginForm {...props} />
        <ForgotPasswordButton className={classes.forgotPasswordButton} {...props} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='h6' align='center'>Sign In with Oauth</Typography>
        <Box display='flex' flexDirection='column' className={classes.oauthContainer}>
          <GoogleSignInButton
            fullWidth
            label='Sign in with Google'
            onSignIn={() => {
              window.location.href = window.location.origin
            }}
          />
          <div style={{ height: 16 }} />
          <FacebookSignInButton
            fullWidth
            label='Sign in with Facebook'
            onSignIn={() => {
              window.location.href = window.location.origin
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

const CustomLoginPage = props => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
)

export default CustomLoginPage
