import { Button } from '@material-ui/core'
import React from 'react'

const ClearFitersButton = ({ setFilters }) => {
  return (
    <Button
      color='primary'
      size='small'
      onClick={() => setFilters({})}
      style={{ marginBottom: 12 }}
    >Clear Filters
    </Button>
  )
}

ClearFitersButton.defaultProps = {
  alwaysOn: true,
  source: 'clear'
}

export default ClearFitersButton
