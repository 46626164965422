import React from 'react'
import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import ProfilePictureField from '../components/fields/ProfilePictureField'
import DefaultPagination from '../components/layout/DefaultPagination'

export const ClientProfilesList = ({ ...props }) => (
  <List
    pagination={<DefaultPagination />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='generalInfo.firstName' label='First Name' />
      <TextField source='generalInfo.lastName' label='Last Name' />
    </Datagrid>
  </List>
)

export const ClientProfilesShow = ({ ...props }) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ProfilePictureField />
        <TextField source='id' />
        <TextField source='generalInfo.firstName' label='First Name' />
        <TextField source='generalInfo.lastName' label='Last Name' />
        <TextField source='generalInfo.city' label='City' />
        <TextField source='generalInfo.country' label='Country' />
        <TextField source='generalInfo.phone' label='Phone Number' />
        <TextField source='biography' />
      </SimpleShowLayout>
    </Show>
  )
}
