import React from 'react'
import { SelectInput } from 'react-admin'

const TitleInput = ({ ...props }) => {
  return (
    <SelectInput
      source='title'
      choices={[
        { id: 'Mr.', name: 'Mr.' },
        { id: 'Mrs.', name: 'Mrs.' },
        { id: 'Miss', name: 'Miss' },
        { id: 'Dr', name: 'Dr.' },
        { id: 'Ms', name: 'Ms.' },
        { id: 'Prof.', name: 'Prof.' },
        { id: 'Rev.', name: 'Rev.' },
        { id: 'Lady', name: 'Lady' },
        { id: 'Sir', name: 'Sir' }
      ]}
      {...props}
    />
  )
}

TitleInput.defaultProps = {
  source: 'title'
}

export default TitleInput
