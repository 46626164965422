import { Box, Collapse, Divider, ListItem, ListItemIcon, ListItemText, ListSubheader, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ContactsIcon from '@material-ui/icons/Contacts'
import DefaultIcon from '@material-ui/icons/ViewList'
import WorkIcon from '@material-ui/icons/Work'
import { camelCase, startCase } from 'lodash'
import React, { useState } from 'react'
import { getResources, MenuItemLink } from 'react-admin'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

function hexToRGB (hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  insetLevel1: {
    paddingLeft: theme.spacing(4)
  },
  active: {
    backgroundColor: hexToRGB(theme.palette.primary.light, 0.1)
  }
}))

const InsetMenuItem = ({ route, children }) => {
  const classes = useStyles()
  const location = useLocation()
  const matchRoute = (route) => () => {
    return location.pathname.startsWith(route)
  }

  return (
    <ListItem
      button
      className={classes.insetLevel1}
      dense
      component={NavLink}
      to={route}
      activeClassName={classes.active}
      isActive={matchRoute(route)}
    >{children}
    </ListItem>
  )
}

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const classes = useStyles()

  const [contactsOpen, setContactsOpen] = useState(false)
  const [projectsOpen, setProjectsOpen] = useState(false)
  return (
    <div>
      <ListSubheader>Custom Resources</ListSubheader>

      <ListItem button onClick={() => setContactsOpen(!contactsOpen)}>
        <ListItemIcon>
          <ContactsIcon />
        </ListItemIcon>
        <ListItemText primary='Contacts' />
      </ListItem>
      <Collapse in={contactsOpen} timeout='auto' unmountOnExit>
        <InsetMenuItem route='/custom-contacts/all'>
          <ListItemText inset primary='All' />
        </InsetMenuItem>
        <InsetMenuItem route='/custom-contacts/freelancers'>
          <ListItemText inset primary='Freelancers' />
        </InsetMenuItem>
        <InsetMenuItem route='/custom-contacts/clients'>
          <ListItemText inset primary='Clients' />
        </InsetMenuItem>
      </Collapse>

      <ListItem button onClick={() => setProjectsOpen(!projectsOpen)}>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary='Projects' />
      </ListItem>
      <Collapse in={projectsOpen}>
        <InsetMenuItem route='/custom-projects/all'>
          <ListItemText inset primary='All' />
        </InsetMenuItem>
        <InsetMenuItem route='/custom-projects/pending'>
          <ListItemText inset primary='Pending' />
        </InsetMenuItem>
        <InsetMenuItem route='/custom-projects/in_progress'>
          <ListItemText inset primary='In Progress' />
        </InsetMenuItem>
        <InsetMenuItem route='/custom-projects/completed'>
          <ListItemText inset primary='Completed' />
        </InsetMenuItem>
      </Collapse>

      <Box my={2}>
        <Divider className={classes.divider} />
      </Box>
      <ListSubheader>Database Resources</ListSubheader>
      {
        resources.map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              startCase(camelCase(
                (resource.options && resource.options.label) || resource.name
              ))
            }
            leftIcon={
              resource.icon ? <resource.icon /> : <DefaultIcon />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))
      }
      {isXSmall && logout}
    </div>
  )
}

export default Menu
