import { DateField } from 'react-admin'
import React from 'react'

const StandardDateField = props => {
  return (
    <DateField {...props} />
  )
}

StandardDateField.defaultProps = {
  options: { dateStyle: 'medium', timeStyle: 'medium' },
  showTime: true,
  addLabel: true
}

export default StandardDateField
