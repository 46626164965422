import React from 'react'
import { SelectField } from 'react-admin'

const StatusField = ({ ...props }) => {
  return (
    <SelectField
      source='status'
      label='Status'
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'in_progress', name: 'In Progress' },
        { id: 'qa', name: 'QA' },
        { id: 'completed', name: 'Completed' },
        { id: 'cancelled', name: 'Cancelled' }
      ]}
      {...props}
    />
  )
}

StatusField.defaultProps = {
  addLabel: true,
  label: 'Status'
}

export default StatusField
