import { Flex } from '@leanlancer/ui'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { SimpleShowLayout, TextField } from 'react-admin'

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 4,
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%'
  }
}))

const AddressField = ({ ...props }) => {
  const classes = useStyles()
  return (
    <Flex dir='ttb' className={classes.container}>
      <SimpleShowLayout {...props}>
        <TextField addLabel label='Line 1' source='address.address_line_1' />
        <TextField addLabel label='Line 2' source='address.address_line_2' />
        <TextField addLabel label='Line 3' source='address.address_line_3' />
        <TextField addLabel label='Line 4' source='address.address_line_4' />
        <TextField addLabel label='City' source='address.address_city' />
        <TextField addLabel label='State' source='address.address_state' />
        <TextField addLabel label='Country' source='address.address_country' />
        <TextField addLabel label='Postcode' source='address.address_postcode' />
        <TextField addLabel label='Additional Info' source='address.address_additional_info' />
      </SimpleShowLayout>
    </Flex>
  )
}

AddressField.defaultProps = {
  addLabel: true,
  label: 'Address'
}

export default AddressField
