import React from 'react'
import DocumentListField from './DocumentListField'

const CVFileListField = ({ record }) => {
  const storageRef = firebase.storage().ref(`cvs/${record?.id}`)
  return (
    <DocumentListField storageRef={storageRef} />
  )
}

CVFileListField.defaultProps = {
  addLabel: true,
  label: 'CV Files'
}

export default CVFileListField
