import React from 'react'
import {
  BooleanField,
  BooleanInput, Datagrid,
  DateField,
  EmailField,
  Filter, List,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import { ShowSplitter } from 'ra-compact-ui'
import ClearFitersButton from '../../components/filters/ClearFitersButton'
import DefaultPagination from '../../components/layout/DefaultPagination'
import StandardDateField from '../../components/fields/StandardDateField'
import TextArrayField from '../../components/fields/TextArrayField'
import { Typography } from '@material-ui/core'
import JSONField from '../../components/fields/JSONField'

const LeadsFilter = props => (
  <Filter {...props}>
    <ClearFitersButton {...props} />
    <BooleanInput source='submitted' alwaysOn />
    <SelectInput
      source='type'
      alwaysOn
      choices={[
        { id: 'default', name: 'default' },
        { id: 'website', name: 'website' },
        { id: 'webshop', name: 'webshop' },
        { id: 'seo', name: 'seo' },
        { id: 'sea', name: 'sea' },
        { id: 'app', name: 'app' }
      ]}
    />
  </Filter>
)

export const LeadsList = ({ ...props }) => (
  <List
    {...props}
    filters={<LeadsFilter />}
    pagination={<DefaultPagination />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <StandardDateField source='createdAt' />
      <StandardDateField source='updatedAt' />
      <BooleanField source='submitted' />
      <TextField source='type' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <EmailField source='email' />
      <TextField source='phoneNumber' />
      <TextField source='companyName' />
      <TextField source='companyHomepage' />
    </Datagrid>
  </List>
)

const LeadForm = ({ ...props }) => {
  let form = null
  switch (props?.record?.type) {
    case 'website':
      form = (
        <SimpleShowLayout {...props}>
          <>
            <Typography variant='h6'>Type: Website</Typography>
          </>
          <BooleanField source='existingWebsite' />
          <TextArrayField source='websiteChanges' />
          <TextArrayField source='websitePuropse' />
          <BooleanField source='hasContent' />
        </SimpleShowLayout>
      )
      break
    case 'webshop':
      form = (
        <SimpleShowLayout {...props}>
          <>
            <Typography variant='h6'>Type: Webshop</Typography>
          </>
          <BooleanField source='existingWebsite' />
          <TextArrayField source='webshopChanges' />
          <TextArrayField source='webshopPurpose' />
          <BooleanField source='existingContent' />
        </SimpleShowLayout>
      )
      break
    case 'sea':
      form = (
        <SimpleShowLayout {...props}>
          <>
            <Typography variant='h6'>Type: SEA</Typography>
          </>
          <BooleanField source='existingAccount' />
          <TextArrayField source='seaChanges' />
          <TextArrayField source='seaPurpose' />
          <BooleanField source='existingContent' />
        </SimpleShowLayout>
      )
      break
    case 'seo':
      form = (
        <SimpleShowLayout {...props}>
          <>
            <Typography variant='h6'>Type: SEO</Typography>
          </>
          <BooleanField source='existingWebsite' />
          <BooleanField source='existingSeo' />
          <TextArrayField source='seoChanges' />
          <TextArrayField source='seoPurpose' />
        </SimpleShowLayout>
      )
      break
    case 'app':
      form = (
        <SimpleShowLayout {...props}>
          <>
            <Typography variant='h6'>Type: App</Typography>
          </>
          <BooleanField source='existingApplication' />
          <TextArrayField source='appPlatforms' />
          <TextArrayField source='appPurpose' />
          <BooleanField source='existingDesign' />
        </SimpleShowLayout>
      )
      break
    default:
      form = (
        <SimpleShowLayout {...props}>
          <>
            <Typography variant='h6'>Type: Default</Typography>
          </>
          <TextField source='title' />
          <TextField source='description' />
          <TextField source='additionalInfo' />
          <TextField source='approxBudget' />
          <DateField source='projectStart' />
          <DateField source='projectEnd' />
          <TextArrayField source='goals' />
          <TextArrayField source='services' />
        </SimpleShowLayout>
      )
  }
  return (
    <SimpleShowLayout {...props}>
      {form}
      <JSONField source='params' />
    </SimpleShowLayout>
  )
}

export const LeadsShow = ({ ...props }) => (
  <Show {...props} component='div'>
    <ShowSplitter
      leftSide={
        <SimpleShowLayout>
          <StandardDateField source='createdAt' />
          <StandardDateField source='updatedAt' />
          <BooleanField source='submitted' />

          <TextField source='firstName' />
          <TextField source='lastName' />
          <TextField source='email' />
          <TextField source='phoneNumber' />
        </SimpleShowLayout>
      }
      rightSide={
        <LeadForm />
      }
    />
  </Show>
)
