import React from 'react'
import {
  ArrayInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  List,
  NumberField,
  NumberInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'
// import ClearFiltersButton from '../components/filters/ClearFiltersButton';

const ClientInvoicesFilter = (props) => (
  <Filter {...props}>
    {/* <ClearFiltersButton {...props} /> */}
    {/* Add filter fields specific to client invoices */}
  </Filter>
)

export const ClientInvoicesList = (props) => (
  <List filters={<ClientInvoicesFilter />} {...props}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='invoiceNumber' />
      <TextField source='clientName' />
      <DateField source='issueDate' />
      <DateField source='dueDate' />
      <NumberField source='totalAmount' />
      <TextField source='status' />
    </Datagrid>
  </List>
)

export const ClientInvoiceShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id' />
      <TextField source='invoiceNumber' />
      <TextField source='clientName' />
      <DateField source='issueDate' />
      <DateField source='dueDate' />
      <NumberField source='totalAmount' />
      <TextField source='status' />
      <Datagrid source='items'>
        <TextField source='id' />
        <TextField source='description' />
        <NumberField source='quantity' />
        <NumberField source='unitPrice' />
        <NumberField source='totalPrice' />
      </Datagrid>
    </SimpleShowLayout>
  </Show>
)

export const ClientInvoiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source='id' disabled />
      <TextInput source='invoiceNumber' />
      <TextInput source='clientName' />
      <DateInput source='issueDate' />
      <DateInput source='dueDate' />
      <NumberInput source='totalAmount' />
      <TextInput source='status' />
      <ArrayInput source='items'>
        <SimpleFormIterator>
          <TextInput source='id' disabled />
          <TextInput source='description' />
          <NumberInput source='quantity' />
          <NumberInput source='unitPrice' />
          <NumberField
            source='totalPrice'
            label='Total Price'
            disabled
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)

export const ClientInvoiceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='invoiceNumber' />
      <TextInput source='clientName' />
      <DateInput source='issueDate' />
      <DateInput source='dueDate' />
      <NumberInput source='totalAmount' />
      <TextInput source='status' />
      <ArrayInput source='items'>
        <SimpleFormIterator>
          <TextInput source='description' />
          <NumberInput source='quantity' />
          <NumberInput source='unitPrice' />
          <NumberField
            source='totalPrice'
            label='Total Price'
            disabled
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)
