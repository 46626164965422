import React from 'react'
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin'

const PermissionsViewerInput = ({ selectProps = {}, ...props }) => {
  return (
    <ReferenceArrayInput
      label='User Select'
      source='permissions.viewers'
      reference='users'
      filter={{ isAnonymous: false }}
      {...props}
    >
      <SelectArrayInput optionText='email' label='Email' {...selectProps} />
    </ReferenceArrayInput>
  )
}

export default PermissionsViewerInput
