import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'
import DefaultPagination from '../components/layout/DefaultPagination'
import PermissionsViewerField from '../components/fields/PermissionsViewerField'
import PermissionsViewerInput from '../components/fields/PermissionsViewerInput'

export const ProjectMilestonesList = ({ ...props }) => (
  <List
    pagination={<DefaultPagination />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <ReferenceField label='Parent Project' source='projectId' reference='projects'>
        <TextField source='title' />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const ProjectMilestonesShow = ({ ...props }) => {
  return (
    <Show
      {...props}
    >
      <SimpleShowLayout>
        <TextField source='id' />
        <TextField source='title' />
        <ReferenceField label='Parent Project' source='projectId' reference='projects'>
          <TextField source='title' />
        </ReferenceField>
        <PermissionsViewerField label='Milestone Viewers' />
      </SimpleShowLayout>
    </Show>
  )
}

export const ProjectMilestonesEdit = ({ ...props }) => {
  return (
    <Edit
      {...props}
    >
      <SimpleForm>
        <TextField source='id' />
        <TextInput source='title' />
        <ReferenceInput label='Parent Project' source='projectId' reference='projects'>
          <SelectInput optionText='title' />
        </ReferenceInput>
        <PermissionsViewerInput label='Milestone Viewers' />
      </SimpleForm>
    </Edit>
  )
}

export const ProjectMilestonesCreate = ({ ...props }) => {
  return (
    <Create
      {...props}
    >
      <SimpleForm>
        <TextInput source='title' />
        <ReferenceInput label='Parent Project' source='projectId' reference='projects'>
          <SelectInput optionText='title' />
        </ReferenceInput>
        <PermissionsViewerInput label='Milestone Viewers' />
      </SimpleForm>
    </Create>
  )
}
