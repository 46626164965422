import { Box, Button, Menu, MenuItem } from '@material-ui/core'
import React from 'react'
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  List,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Filter
} from 'react-admin'
import ClearFitersButton from '../components/filters/ClearFitersButton'

const functions = firebase.app().functions('europe-west1')
const sendEmailVerificationLink = functions.httpsCallable('mandrill-sendEmailVerificationLink')

const UsersFilter = props => (
  <Filter {...props}>
    <ClearFitersButton {...props} />
    <TextInput label='Filter by email' source='email' alwaysOn />
    <TextInput label='Filter by id' source='id' alwaysOn />
  </Filter>
)

const UserActions = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handeSendEmail = async () => {
    handleClose()
    const result = await sendEmailVerificationLink({
      redirectUrl: 'https://leanlancer.com/login',
      email: data?.email
    })
    console.log('-> result', result)
  }

  return (
    <Box my={1}>
      <Button
        size='small'
        variant='contained'
        disabled={!data?.email}
        onClick={handleOpenMenu}
      >Send Email
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handeSendEmail}>Verify Email</MenuItem>
      </Menu>
    </Box>
  )
}

export const UserList = ({ ...props }) => (
  <List
    {...props}
    filters={<UsersFilter />}
    filter={{
      isAnonymous: false
    }}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
      <TextField source='displayName' />
      <TextField source='email' />
      <TextField source='phoneNumber' />
      <BooleanField source='emailVerified' />
    </Datagrid>
  </List>
)

export const UserShow = ({ ...props }) => (
  <Show
    {...props}
    actions={<UserActions {...props} />}
  >
    <SimpleShowLayout>
      <TextField source='id' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
      <TextField source='displayName' />
      <TextField source='email' />
      <TextField source='phoneNumber' />
      <BooleanField source='emailVerified' />
      <ArrayField source='providerData'>
        <SingleFieldList>
          <ChipField source='providerId' />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)
