import {
  Filter,
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  Datagrid,
  List,
  TextInput,
  Create,
  EmailField,
  SimpleForm,
  Edit
} from 'react-admin'
import ClearFitersButton from '../components/filters/ClearFitersButton'
import StandardDateField from '../components/fields/StandardDateField'
import React from 'react'
import TitleInput from '../components/inputs/TitleInput'
import AddressInput from '../components/inputs/AddressInput'
import AddressField from '../components/fields/AddressField'

const ContactFilter = props => (
  <Filter {...props}>
    <ClearFitersButton {...props} />
    <TextInput label='Filter by id' source='id' alwaysOn />
    <TextInput label='Filter by email' source='email' alwaysOn />
  </Filter>
)

export const ContactList = ({ ...props }) => (
  <List
    {...props}
    filters={<ContactFilter />}
    filter={{
      isAnonymous: false
    }}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <StandardDateField source='createdAt' />
      <StandardDateField source='updatedAt' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <EmailField source='email' />
      <BooleanField source='emailVerified' />
      <TextField source='phoneNumber' />
    </Datagrid>
  </List>
)

export const ContactCreate = ({ ...props }) => (
  <Create
    {...props}
  // actions={<ContactActions {...props} />}
  >
    <SimpleForm>
      <TitleInput />
      <TextInput source='firstName' />
      <TextInput source='lastName' />
      <TextInput source='email' type='email' />
      <BooleanField source='emailVerified' />
      <TextInput source='phoneNumber' />
      <AddressInput />
    </SimpleForm>
  </Create>
)

export const ContactEdit = ({ ...props }) => {
  return (
    <Edit
      {...props}
    // actions={<ContactActions {...props} />}
    >
      <SimpleForm>
        <TextField source='id' />
        <StandardDateField source='createdAt' />
        <StandardDateField source='updatedAt' />
        {/* <ImageInput source='profile_picture_url' title='Profile Picture' accept='image/*' /> */}
        <TitleInput />
        <TextInput source='firstName' />
        <TextInput source='lastName' />
        <TextInput source='email' type='email' />
        <BooleanField source='emailVerified' />
        <TextInput source='phoneNumber' />
        <AddressInput />
      </SimpleForm>
    </Edit>
  )
}

export const ContactShow = ({ ...props }) => {
  return (
    <Show
      {...props}
    // actions={<ContactActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source='id' />
        <StandardDateField source='createdAt' />
        <StandardDateField source='updatedAt' />
        {/* <ImageField source='profile_picture_url' title='Profile Picture' /> */}
        <TextField source='title' />
        <TextField source='firstName' />
        <TextField source='lastName' />
        <EmailField source='email' />
        <BooleanField source='emailVerified' />
        <AddressField />
      </SimpleShowLayout>
    </Show>
  )
}
