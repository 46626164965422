import React from 'react'
import { SelectInput } from 'react-admin'

const StatusInput = ({ ...props }) => {
  return (
    <SelectInput
      source='status'
      emptyValue='pending'
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'in_progress', name: 'In Progress' },
        { id: 'qa', name: 'QA' },
        { id: 'completed', name: 'Completed' },
        { id: 'cancelled', name: 'Cancelled' }
      ]}
      {...props}
    />
  )
}

StatusInput.defaultProps = {
  source: 'status'
}

export default StatusInput
