import React from 'react'
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'
import ClearFitersButton from '../components/filters/ClearFitersButton'
import DefaultPagination from '../components/layout/DefaultPagination'
import PermissionsViewerField from '../components/fields/PermissionsViewerField'
import PermissionsViewerInput from '../components/fields/PermissionsViewerInput'
import StatusField from '../components/fields/StatusField'
import StatusInput from '../components/inputs/StatusInput'

const ProjectTasksFilter = props => (
  <Filter {...props}>
    <ClearFitersButton {...props} />
    <ReferenceInput label='Project' source='projectId' reference='projects' alwaysOn>
      <SelectInput optionText='title' />
    </ReferenceInput>
    <ReferenceInput label='Milestone' source='milestoneId' reference='project_milestones' alwaysOn>
      <SelectInput optionText='title' />
    </ReferenceInput>
    <StatusInput alwaysOn />
  </Filter>
)

export const ProjectTasksList = ({ ...props }) => (
  <List
    filters={<ProjectTasksFilter />}
    pagination={<DefaultPagination />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <ReferenceField label='Parent Project' source='projectId' reference='projects'>
        <TextField source='title' />
      </ReferenceField>
      <ReferenceField label='Parent Milestone' source='milestoneId' reference='project_milestones'>
        <TextField source='title' />
      </ReferenceField>
      <DateField source='startDate' />
      <DateField source='endDate' />
      <StatusField />
    </Datagrid>
  </List>
)

export const ProjectTasksShow = ({ ...props }) => {
  return (
    <Show
      {...props}
    >
      <SimpleShowLayout>
        <TextField source='id' />
        <TextField source='title' />
        <ReferenceField label='Parent Project' source='projectId' reference='projects'>
          <TextField source='title' />
        </ReferenceField>
        <ReferenceField label='Parent Milestone' source='milestoneId' reference='project_milestones'>
          <TextField source='title' />
        </ReferenceField>
        <DateField source='startDate' />
        <DateField source='endDate' />
        <StatusField />
        <PermissionsViewerField label='Task Viewers' />
      </SimpleShowLayout>
    </Show>
  )
}

export const ProjectTasksEdit = ({ ...props }) => {
  return (
    <Edit
      {...props}
    >
      <SimpleForm>
        <TextField source='id' />
        <TextInput source='title' />
        <ReferenceInput label='Parent Project' source='projectId' reference='projects'>
          <SelectInput optionText='title' />
        </ReferenceInput>
        {/* TODO filter on parent project id */}
        <ReferenceInput
          label='Parent Milestone'
          source='milestoneId'
          reference='project_milestones'
        >
          <SelectInput optionText='title' />
        </ReferenceInput>
        <DateInput source='startDate' />
        <DateInput source='endDate' />
        <StatusInput />
        <PermissionsViewerInput label='Task Viewers' />
      </SimpleForm>
    </Edit>
  )
}

export const ProjectTasksCreate = ({ ...props }) => {
  return (
    <Create
      {...props}
    >
      <SimpleForm>
        <TextInput source='title' />
        <ReferenceInput label='Parent Project' source='projectId' reference='projects'>
          <SelectInput optionText='title' />
        </ReferenceInput>
        {/* TODO filter on parent project id */}
        <ReferenceInput label='Parent Milestone' source='milestoneId' reference='project_milestones'>
          <SelectInput optionText='title' />
        </ReferenceInput>
        <DateInput source='startDate' />
        <DateInput source='endDate' />
        <StatusInput />
        <PermissionsViewerInput label='Task Viewers' />
      </SimpleForm>
    </Create>
  )
}
