import { get } from 'lodash'
import React from 'react'
import ReactJson from 'react-json-view'

const JSONField = ({
  source,
  record = {},
  jsonString = false,
  reactJsonOptions = {
    enableClipboard: false,
    iconStyle: 'circle',
    name: source,
    displayDataTypes: false,
    displayObjectSize: false,
    quotesOnKeys: false
  }
}) => {
  let src = get(record, source)

  if (jsonString) {
    src = JSON.parse(src)
  }

  return <ReactJson {...reactJsonOptions} src={src || {}} />
}

export default JSONField
