import React from 'react'
import { ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin'

const PermissionsViewerField = ({ ...props }) => {
  return (
    <ReferenceArrayField label='Viewers' source='permissions.viewers' reference='users' {...props}>
      <SingleFieldList>
        <ChipField source='email' addLabel />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

PermissionsViewerField.defaultProps = {
  addLabel: true
}

export default PermissionsViewerField
