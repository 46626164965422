import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
  Create,
  Datagrid,
  DateField,
  DateInput, Edit,
  List,
  NumberField,
  NumberInput,
  RichTextField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'
import DefaultPagination from '../components/layout/DefaultPagination'
import PermissionsViewerField from '../components/fields/PermissionsViewerField'
import PermissionsViewerInput from '../components/fields/PermissionsViewerInput'
import StatusInput from '../components/inputs/StatusInput'

export const ProjectsList = ({ ...props }) => (
  <List
    pagination={<DefaultPagination />}
    {...props}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <NumberField source='totalCost' label='Total Cost (‎€)' />
      <TextField source='status' />
      <TextField source='status' />
      <DateField source='startDate' />
    </Datagrid>
  </List>
)

export const ProjectsShow = ({ ...props }) => {
  return (
    <Show
      {...props}
    >
      <SimpleShowLayout>
        <TextField source='id' />
        <TextField source='title' />
        <RichTextField source='description' />
        <TextField source='status' />
        <DateField source='startDate' />
        <DateField source='endDate' />
        <PermissionsViewerField label='Project Viewers' />
      </SimpleShowLayout>
    </Show>
  )
}

export const ProjectsEdit = ({ ...props }) => {
  return (
    <Edit
      {...props}
    >
      <SimpleForm>
        <TextField source='id' />
        <TextField source='title' />
        <RichTextInput source='description' />
        <DateInput source='startDate' />
        <DateInput source='endDate' />
        <NumberInput source='totalCost' label='Total Cost (‎€)' />
        <StatusInput />
        <PermissionsViewerInput label='Project Viewers' />
      </SimpleForm>
    </Edit>
  )
}

export const ProjectsCreate = ({ ...props }) => {
  return (
    <Create
      {...props}
    >
      <SimpleForm>
        <TextInput source='title' />
        <RichTextInput source='description' />
        <DateInput source='startDate' />
        <DateInput source='endDate' />
        <NumberInput source='totalCost' label='Total Cost (‎€)' />
        <StatusInput />
        <PermissionsViewerInput label='Project Viewers' />
      </SimpleForm>
    </Create>
  )
}
