import { Avatar, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: ({ size }) => theme.spacing(size),
    height: ({ size }) => theme.spacing(size)
  }
}))

const ProfilePictureField = ({ record, size = 10, ...props }) => {
  const classes = useStyles({ size })
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (record.id) {
      const profilePictureRef = firebase.storage().ref(`profile_pictures/${record.id}`)
      profilePictureRef.getDownloadURL()
        .then((dlUrl) => {
          setUrl(dlUrl)
        }).finally(() => {
          setLoading(false)
        })
    }
  }, [record?.id])

  return loading
    ? (
      <Avatar className={classes.avatar}>
        <CircularProgress />
      </Avatar>
      )
    : <Avatar src={url} className={classes.avatar} />
}

export default ProfilePictureField
