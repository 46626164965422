import { defaultTheme } from 'react-admin'
import { indigo } from '@material-ui/core/colors'
import merge from 'lodash/merge'
import { muiTheme } from '@leanlancer/ui'

const adminTheme = merge({}, muiTheme, defaultTheme, {
  palette: {
    primary: indigo,
    secondary: muiTheme.palette.primary,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  overrides: {
    MuiButton: { // override the styles of all instances of this component
      root: { // Name of the rule
        color: 'white' // Some CSS
      }
    }
  }
})

export default adminTheme
