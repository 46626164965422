// in src/MyLayout.js
import { AppBar, Layout, Notification, Sidebar } from 'react-admin'
// import MySidebar from './MySidebar'
import CustomMenu from './CustomMenu'
// import MyNotification from './MyNotification'

const MyLayout = props => (
  <Layout
    {...props}
    appBar={AppBar}
    sidebar={Sidebar}
    menu={CustomMenu}
    notification={Notification}
  />
)

export default MyLayout
